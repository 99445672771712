export default [
  {
    text: "Я студент ВУЗа",
    value: 1,
  },
  {
    text: "Я студент СПО",
    value: 2,
  },
];
